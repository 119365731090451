<template>
  <div class="aboutUsBox">
    <div class="box_top" v-if="container.coverPic">
      <img :src="container.coverPic" alt=""/>
    </div>
    <div class="box_bottom" v-if="container.length !== 0">
      <h3>{{ container.title }}</h3>
      <p>{{ container.updateTime }} {{ container.updateUser }}</p>
      <!-- 这一块是获取的内容 -->
      <div v-html="container.content"></div>
    </div>
    <div v-if="noData">
      <lostPage></lostPage>
    </div>
  </div>
</template>

<script>
import axios from "../assets/axios/axios";
import loadEvents from "../utils/loading";
import lostPage from "../components/common/404/404";

let loads = new loadEvents();
export default {
  components: {
    lostPage,
  },
  data() {
    return {
      // 内容
      container: [],
      // loading: true
      noData: false,
      p: null,
    };
  },
  methods: {
    getPage() {
      loads.open();
      var path = this.$route.params.slug;
      this.container = [];
      axios.getArticle(path, this.p).then((res) => {
        const {data, lang} = res.data;
        loads.close();
        if (!data) {
          this.noData = true;
          return;
        }
        this.container = data;
        setTimeout(() => {
          document.title =
              data.title +
              "-" +
              this.$store.state.logoAndRight["company.vision." + lang];
        }, 50);
      });
    },
    getReferer() {
      this.p = this.$route.query.p || "";
    },
  },
  created() {
    this.getReferer();
    this.getPage();
  },
  mounted() {
  },
};
</script>

<style scoped="scoped" lang="less">
.aboutUsBox {
  // 盒子顶部
  .box_top {
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;

    // height: 26.875rem;
    img {
      width: 100%;
    }
  }

  // 盒子底部
  .box_bottom {
    width: 80%;
    margin: 0 auto;
    padding: 2rem;
  }
}

@media screen and (max-width: 768px) {
  .box_bottom {
    width: 90% !important;
    margin: 0 auto;
    padding: 2rem;
  }
}

@media screen and (max-width: 550px) {
  .box_bottom {
    width: 100% !important;
    margin: 0 auto;
    padding: 2rem;
  }
}
</style>
