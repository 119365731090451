<template>
  <div class="lostPage">
    <div class="imgBox imgBox2">
      <img :src="lostImg" alt="" />
    </div>
    <div class="holdBox">
      <h2>4 0 4</h2>
      <p>正在加油中，请耐心等待，我们竭尽全力为您服务</p>
      <div>
        <div>
          <el-button type="primary">去首页</el-button>
          <el-button class="backButton">返回上一页</el-button>
        </div>
      </div>
    </div>
    <div class="imgBox imgBox1">
      <img :src="lostImg" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lostImg: require("../../../assets/img/All/car.png"),
    };
  },
};
</script>
<style lang="less" scoped>
.lostPage {
  width: 100%;
  padding: 4% 10% 8% 10%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.holdBox {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start;

  p {
    margin: 40px 0;
    color: #9da2a5;
  }

  h2 {
    letter-spacing: 10px;
    width: 180px;
    font-size: 50px;
  }

  .el-button {
    width: 180px;
    height: 60px;
    border-radius: 30px;
    font-weight: bold;
  }

  .backButton {
    color: #1997e3;
    border: 1px solid #1997e3;
    margin-left: 20px;
  }
}

.imgBox {
  width: 60%;

  img {
    margin: 0 auto;
    max-width: 100%;
  }
}

.imgBox2 {
  display: none;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .holdBox {
    h2 {
      font-size: 40px;
    }

    .el-button {
      width: 150px;
      height: 50px;
      border-radius: 25px;
    }

    .backButton {
      margin-left: 10px;
    }
  }

  .imgBox {
    width: 60%;

    img {
      max-width: 100%;
    }
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .holdBox {
    h2 {
      font-size: 30px;
      letter-spacing: 5px;
    }

    .el-button {
      width: 120px;
      height: 40px;
      border-radius: 20px;
    }

    .backButton {
      margin-left: 10px;
    }
  }

  .imgBox {
    width: 60%;

    img {
      max-width: 100%;
    }
  }
}

@media screen and (max-width: 768px) {
  .lostPage {
    flex-wrap: wrap;
  }

  .holdBox {
    margin-top: 2rem;

    h2 {
      font-size: 30px;
      letter-spacing: 5px;
    }

    p {
      margin: 20px 0;
    }

    .el-button {
      width: 120px;
      height: 40px;
      border-radius: 20px;
    }

    .backButton {
      margin-left: 10px;
    }
  }

  .imgBox {
    width: 80%;

    img {
      max-width: 100%;
    }
  }

  .imgBox1 {
    display: none;
  }

  .imgBox2 {
    display: block;
  }
}
</style>
